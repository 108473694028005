// Nav at the top
.headroom--top {
  background: rgba(255, 255, 255, 0);
  height: 100px;
  padding-left: 80px;
  padding-right: 80px;
}

// Nav after scrolling
.headroom--unpinned,
.headroom--pinned,
.headroom {
  background: #1c1c1c9b;
  height: 100px;
  padding-left: 40px;
  padding-right: 40px;
}
