*:focus {
  outline: none;
}

body,
html {
  color: #181a19;
  font-family: "Plus Jakarta Sans", sans-serif;
}

// Sites Wrapper
.wrapper {
  max-width: 1680px !important;
  margin: auto !important;

  // Big Screens
  padding-left: 140px !important;
  padding-right: 140px !important;

  // Laptop Screens
  @media (max-width: 1536px) {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }

  // Tablet Screens
  @media (max-width: 1280px) {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }

  // Phone Screens
  @media (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

// Titles & Headings
h1,
.h1,
h2,
.h2 {
  font-weight: 600;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: responsive 30px 50px;
  line-height: responsive 33px 50px;
  margin-bottom: 1rem;
  a {
    font-size: responsive 30px 50px;
    line-height: responsive 33px 50px;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.description {
  p {
    font-weight: 400;
  }
}
// Sub Headings
h3,
.h3 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-size: responsive 24px 35px;
  line-height: responsive 25px 40px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  strong {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 800;
  }
}

h4,
.h4 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 800;
  font-size: responsive 15px 16px;
  line-height: responsive 15px 16px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  strong {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 800;
  }
}

// Generic Text, buttons, links etc.
p,
input,
a,
li,
button {
  font-weight: 300;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-size: responsive 14px 16px;
  line-height: responsive 18px 22px;
  outline: none !important;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
  strong {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-weight: 700;
  }
}
.over-container {
  max-width: 1600px;
  margin: auto;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  overflow: hidden;
  margin-top: -2rem;
  position: relative;
  z-index: 10;
}
.dropdown-item {
  display: none;
}
.header-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 40px 0 178px 60px;
  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .div4 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div5 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div6 {
    grid-area: 2 / 3 / 3 / 4;
  }
  .div7 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .div8 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .div9 {
    grid-area: 3 / 3 / 4 / 4;
  }
}
.ent-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  padding: 40px 0 178px 60px;
}

.div1 {
  grid-area: 1 / 1 / 3 / 3;
}
.div2 {
  grid-area: 1 / 3 / 2 / 4;
}
.div3 {
  grid-area: 2 / 3 / 3 / 4;
}
.div4 {
  grid-area: 3 / 1 / 4 / 2;
}
.div5 {
  grid-area: 3 / 2 / 4 / 3;
}
.div6 {
  grid-area: 3 / 3 / 4 / 4;
}
.job-title {
  position: relative;
  padding: 5px 20px 5px 0;
  line-height: 45px;
  display: inline-block;
  span {
    position: relative;
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    @apply bg-green-light;
  }
}
.job-links {
  font-weight: 800;
  padding-top: 1.25rem;
  a {
    font-weight: 800;
  }
}
.bullets {
  span {
    font-size: 13px;
  }
}
.large-stat {
  font-size: 70px;
  font-weight: 800;
  @media (max-width: 800px) {
    font-size: 50px;
  }
}
.text-green {
  color: #22ead5;
}
.text-pink {
  color: #f23b5a;
}
.text-yellow {
  color: #fdda26;
}
.redactor {
  p {
    margin-bottom: 25px;
  }
}
input[type="file"] {
  display: none;
}
label.upload {
  display: flex;
  position: relative;
  background-color: transparent;
  color: #000;
  font-size: 1.1em;
  text-align: center;
  width: 100%;
  padding: 1em 0;
  margin: 0 auto 1em auto;
  cursor: pointer;
  border: 1px dashed #000;
  height: 100px;
  justify-content: center;
  align-items: center;
}
#image-display {
  position: relative;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  gap: 1.25em;
  flex-wrap: wrap;
}
#image-display figure {
  width: 45%;
}
#image-display img {
  width: 100%;
}
#image-display figcaption {
  font-size: 0.8em;
  text-align: center;
  color: #5a5861;
}
.boxed-btn {
  padding: 1rem;
  @media (max-width: 600px) {
    padding: 0.5rem;
  }
  input[type="checkbox"] {
    display: none;
  }

  .blank-label {
    display: none;
  }

  label {
    display: inline-block;
    border: 1px solid #000;
    cursor: pointer;
    background: transparent;
  }

  label:hover {
    display: inline-block;
    border: 1px solid grey;
    cursor: pointer;
    background: #8eba3d;
  }

  input[type="checkbox"]:checked + label {
    background: #fdda26 !important;
    color: #3c3c3b !important;
  }
}
.mob-nav {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none;
  max-width: 500px;
  right: 0;
  .mob-menu {
    transform: translateX(100%);
  }
}
.mob-nav.open {
  opacity: 1 !important;
  visibility: visible !important;
  pointer-events: all;
  .mob-menu {
    transform: translateX(0%);
  }
}

.top-nav {
  width: calc(100% - 4rem);
  padding: 0 2rem;
  opacity: 0;
  animation: fade-in 0.3s forwards;
  animation-delay: 0.5s;
  @media (max-width: 800px) {
    width: calc(100% - 2rem);
    padding: 0 1rem;
  }
}
.full-text {
  p {
    margin-bottom: 2rem;
  }
  ul {
    margin-left: 20px;
    li {
      list-style: initial;
    }
  }
}
.navi {
  opacity: 0;
  animation: fade-in 0.3s forwards;
  animation-delay: 1.5s;
}
.hero-text {
  opacity: 0;
  animation: fade-in 0.3s forwards;
  animation-delay: 2s;
  h1 {
    strong {
      font-weight: 600;
    }
    strong:nth-of-type(1) {
      color: #f23b5a;
    }
    strong:nth-of-type(2) {
      color: #fdda26;
    }
  }
}
.center-heading {
  strong {
    font-weight: 800;
  }
  strong:nth-of-type(2) {
    color: #f23b5a;
    font-weight: 600;
    border-bottom: 5px solid;
  }
}
.hero-video {
  opacity: 0;
  animation: fade-in 0.3s forwards;
  animation-delay: 2.5s;
}
.main-header {
  width: calc(100% - 4rem);
  @media (max-width: 800px) {
    width: calc(100% - 2rem);
    padding: 0 1rem;
  }
}
.header-insert {
  width: calc(100% - 4rem);
  padding: 0 2rem;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.start-fade {
  opacity: 0;
  animation: fade-in 0.3s forwards;
  animation-delay: 1s;
}
.home-heading {
  strong {
    font-weight: 600;
  }
  strong:nth-of-type(1) {
    color: #22ead5;
    border-bottom: 2px solid #22ead5;
  }
  strong:nth-of-type(2) {
    color: #f23b5a;
    border-bottom: 2px solid #f23b5a;
  }
}
.share-btn {
  color: #333; /* Text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.share-btn i {
  margin-left: 8px;
  font-size: 20px;
}

/* Hover effect */
.share-btn:hover {
  background-color: #e0e0e0;
}
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 40px;
}

.tooltip-content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 125%;
  right: 0;
  // transform: translateX(-50%);
  padding: 20px 15px;
  z-index: 5;
  background-color: #ffffff;
  color: #565656;
  border-radius: 5px;
  border: 1px solid #565656;
  width: 400px;
  font-size: 15px;
  transition: opacity 0.3s, visibility 0.3s;
  @media (max-width: 430px) {
    width: 300px;
    bottom: -538%;
    right: 116%;
  }
  @media (max-width: 430px) {
    width: 300px;
    bottom: -538%;
    right: 0%;
  }
}
.tooltip-cont {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.tooltip-cont:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
.news-wrapper {
  margin: 0 auto;
  max-width: calc(1160px + 20vw);
  padding: 0 10vw;
  width: 100%;
}
.news-block h3 {
  margin-bottom: 2rem;
}
.text-block {
  ul {
    list-style-type: circle !important;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
    li {
      margin-bottom: 15px;
      font-size: 16px;
    }
  }
  .p,
  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 1em;
  }
  h1,
  .h1 {
    font-size: 38px;
    color: white;
    @apply mb-8 font-bold;
  }
  h2,
  .h2 {
    font-size: 28px;
    @apply mb-8 font-bold;
  }

  h3,
  .h3 {
    font-size: 22px;
    @apply font-bold;
  }

  h4,
  .h4 {
    font-size: 15px;
  }

  h5,
  .h5 {
    font-size: 13px;
  }

  h6,
  .h6 {
    font-size: 13px;
  }
}
